import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql, Link } from 'gatsby'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq, rgba } from '@/utils/helpers'
import Typography from './Typography'

const Footer = ({ theme }) => (
  <StaticQuery
    query={query}
    render={data => {
      const offices = data.offices.edges.map(el => el.node)
      const routes = data.routes.edges[0].node
      return (
        <Root theme={theme}>
          <Main>
            <Container>
              <Company theme={theme}>
                <Company_Container>
                  <Company_Title variant="h5">株式会社フロントビジョン</Company_Title>
                  <Company_Offices>
                    {offices.map((office, index) => (
                      <Office key={index}>
                        <Office_Name variant="caption2" component="h5">{office.headquarter ? `本社（${office.area}）` : office.area}</Office_Name>
                        <Office_Info>
                          <Office_Text variant="caption3">〒{office.postalCode} {office.address}</Office_Text>
                          <Office_Text variant="caption3">TEL：{office.tel}</Office_Text>
                          <Office_Text variant="caption3" dangerouslySetInnerHTML={{ __html: office.access }} />
                        </Office_Info>
                      </Office>
                    ))}
                  </Company_Offices>
                </Company_Container>
              </Company>
              <Sitemap>
                <Sitemap_Col>
                  <Sitemap_Row>
                    <PageLink theme={theme} variant="caption3" component={Link} to="/">サイトトップ</PageLink>
                  </Sitemap_Row>
                  {routes.main.slice(0, 5).map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink theme={theme} variant="caption3" component={Link} to={route.path}>{route.title}</PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption3" component={Link} to={route.path}>{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                </Sitemap_Col>
                <Sitemap_Col>
                  {routes.main.slice(5).map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink theme={theme} variant="caption3" component={Link} to={route.path}>{route.title}</PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption3" component={Link} to={route.path}>{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                  {routes.conversion.map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink theme={theme} variant="caption3" component={Link} to={route.path}>{route.title}</PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption3" component={Link} to={route.path}>{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                  {routes.others.map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink noLink theme={theme} variant="caption3">{route.title}</PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption3" component={Link} to={route.path}>{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                  {routes.external.map((route, index) => (
                    <Sitemap_Row key={index}>
                      <PageLink noLink theme={theme} variant="caption3">{route.title}</PageLink>
                      {route.childLists && (
                        <PageChildren>
                          {route.childLists.map((route, index) => (
                            <PageChildLink key={index} theme={theme} variant="caption3" component="a" href={route.path} target="_blank" rel="noopener noreferrer">{route.title}</PageChildLink>
                          ))}
                        </PageChildren>
                      )}
                    </Sitemap_Row>
                  ))}
                </Sitemap_Col>
              </Sitemap>
            </Container>
          </Main>
          <Foot>
            <CopyRight theme={theme}>
              <CopyRight_Container><Typography variant="caption" lang="en" type="default">Copyright © 2020 FRONTVISION All rights reserved.</Typography></CopyRight_Container>
            </CopyRight>
          </Foot>
        </Root>
      )
    }}
  />
)

const Root = styled.div`
  color: ${props => props.theme.text.contrast};
  background-color: ${props => props.theme.background.contrast};
`

const Main = styled(layouts.components.Container({
  base: {
    top: 64,
    bottom: 64
  },
  tablet: {
    top: 48,
    bottom: 48
  },
  mobile: {
    top: 48,
    bottom: 48
  }
}))`
  position: relative;
`

const Foot = styled.div``

const Container = styled.div`
  position: relative;
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
  }
`

const Company = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 24px;
  }
`

const Company_Container = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    margin: 0 auto;
    max-width: 320px;
  }
`

const Company_Title = styled(Typography)``

const Company_Offices = styled.div`
  @media ${mq.and(tabletS)} {
    margin-top: 24px;
    display: flex;
  }
`

const Office = styled.div`
  margin-top: 24px;
  @media ${mq.and(tabletS)} {
    margin-top: 0;
    width: 50%;
  }
`

const Office_Name = styled(Typography)``

const Office_Info = styled.div``

const Office_Text = styled(Typography)``

const Sitemap = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    display: flex;
  }
  @media ${mq.and(tabletS, mobile)} {
    display: flex;
    margin-top: 32px;
  }
`

const Sitemap_Col = styled.div`
  margin-top: -24px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 50%;
  padding-right: 24px;
`

const Sitemap_Row = styled.div`
  margin-top: 24px;
`

const PageLink = styled(Typography)`
  display: block;
  transition: color 0.3s ease;
  font-weight: bold;
  &::before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    width: 24px;
    height: 1px;
    vertical-align: middle;
    background-color: ${props => rgba(props.theme.scale[400], 0.2)};
    @media ${mq.and(mobile)} {
      margin-right: 4px;
      width: 12px;
    }
  }
  @media (hover: hover) {
    &:hover {
      color: ${props => props.noLink
        ? 'currentColor'
        : lighten(props.theme.text.contrast, 0.25)
      };
    }
  }
`

const PageChildren = styled.ul`
`

const PageChildLink = styled(Typography)`
  margin-top: 8px;
  margin-left: 44px;
  display: block;
  transition: color 0.3s ease;
  font-weight: bold;
  color: ${props => lighten(props.theme.text.contrast, 0.5)};

  @media (hover: hover) {
    &:hover {
      color: ${props => lighten(props.theme.text.contrast, 0.75)};
    }
  }

  @media ${mq.and(mobile)} {
    margin-left: 16px;
  }
`

const CopyRight = styled(layouts.components.FullWidth)`
  background-color: ${props => props.theme.scale[800]};
`

const CopyRight_Container = styled(layouts.components.Container())`
  display: flex;
  justify-content: 'flex-start';
  align-items: center;
  height: 64px;
`

export default ThemeConsumer(Footer)

const query = graphql`
  query FooterQuery {
    routes: allRouteYaml {
      edges {
        node {
          main {
            title
            path
            childLists {
              title
              path
            }
          }
          conversion {
            title
            path
            type
          }
          others {
            title
            childLists {
              title
              path
            }
          }
          external {
            title
            childLists {
              title
              path
            }
          }
        }
      }
    }
    offices: allCompanyOfficeListYaml {
      edges {
        node {
          area
          headquarter
          postalCode
          address
          tel
          fax
          access
        }
      }
    }
  }
`
